import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { DEFAULT_VIEW_TYPE, getColorByBgColor } from '../../utils';
import Marquee from 'react-fast-marquee';
import { MARQUEE_ENABLED } from './custom';
import { useEffect } from 'react';

export const Playlist = ({
    tracks,
    currentTrack,
    selectTractIndex,
    viewType,
    swipeHandlers,
    isEmpty,
    playlistData,
}) => {
    const longPressFunction = (event) => {
        const trackIndex = event.currentTarget?.dataset?.trackRow;

        if (!trackIndex) {
            return;
        }

        const track = tracks[trackIndex];
        console.log(track);

        // stop the event from bubbling up
        event.preventDefault();
    };
    useEffect(() => {
        [...document.querySelectorAll('.Playlist__item')].forEach((element) => {
            element.addEventListener('long-press', longPressFunction);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isEmpty) {
        return (
            <div className={`Playlist`}>
                <div className={`Playlist--empty`}>
                    No se encuentran canciones
                </div>
            </div>
        );
    }
    return (
        <div
            className={`Playlist Playlist--${viewType ?? DEFAULT_VIEW_TYPE}`}
            {...swipeHandlers}
        >
            {tracks
                .filter((t) => !t.isFolder)
                .map((song, index) => {
                    const isCurrentTrack =
                        currentTrack && currentTrack.name === song.name;
                    const rowClass = isCurrentTrack
                        ? 'Playlist__item Playlist__item--active'
                        : 'Playlist__item';

                    const bgColor = isCurrentTrack
                        ? (playlistData.theme ?? '#024263')
                        : 'transparent';
                    const textColor = getColorByBgColor(bgColor);

                    return (
                        <div
                            className={rowClass}
                            style={{
                                backgroundColor: bgColor,
                                color: textColor,
                            }}
                            data-track-row={index}
                            onClick={() => selectTractIndex(index)}
                            key={`${song.name}${song.img}`}
                            data-long-press-delay="500"
                        >
                            <div className="Playlist__item__cover">
                                <LazyLoadImage
                                    src={song.img}
                                    alt={song.name}
                                    placeholderSrc={'/server/default_cover.jpg'}
                                />
                            </div>
                            <div className="Playlist__item__number">
                                {index}{' '}
                            </div>
                            <div className="Playlist__item__title">
                                {playlistData.marquee && MARQUEE_ENABLED ? (
                                    <div className="Playlist__item__title__marquee">
                                        <Marquee autoFill={true} speed={20}>
                                            {Array(6)
                                                .fill(0)
                                                .map(() => '\u00A0')}
                                            {'\u00A0'}
                                            {`${song.name}`}
                                        </Marquee>
                                    </div>
                                ) : (
                                    song.name
                                )}
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};
