import { useEffect } from 'react';

export const Menu = ({ clearModal }) => {
    useEffect(() => {
        window?.gapi?.signin2.go();
    }, []);

    return (
        <div className="Modal Menu">
            <div className="Modal--header">
                <div className="Modal--close" onClick={() => clearModal()}>
                    <i className="fa fa-angle-double-down" />
                </div>
                <div className="Modal--title">
                    <h1>Menu</h1>
                </div>
            </div>
            <div className={'Modal__container'}>
                <div className="g-signin2" data-onsuccess="onSignIn">
                    Cuenta
                </div>
            </div>
        </div>
    );
};
