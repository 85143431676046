import {getAllPlaylists, songInfo, spotiDownload, youtubeDownload, youtubeSearch} from '../../api';
import {useEffect, useState} from 'react';

export const Youtube = ({selectedPlaylist: playlist, clearModal}) => {
    const [playlists, setPlaylists] = useState([]);
    const [selectedPlaylist, setSelectedPlaylist] = useState(playlist);
    const [url, setUrl] = useState('https://www.youtube.com/watch?v=wCQfkEkePx8');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [videoData, setVideoData] = useState(undefined)
    const [songs, setSongs] = useState([]);
    const [selectedSongId, setSelectedSongId] = useState(undefined);
    const [selectedSongTitle, setSelectedSongTitle] = useState(undefined);

    useEffect(() => {
        getAllPlaylists().then(({data}) => {
            setPlaylists(data.data);
        });
    }, []);

    useEffect(() => {
        if (videoData?.title) {
            songInfo(videoData.title).then(({data}) => {
                if (data.success) {
                    setSongs(data.data);
                } else {
                    setSelectedSongTitle(videoData.title);
                }
            });
        }
    }, [videoData?.title])

    const search = (event) => {
        setIsLoading(true);
        event?.preventDefault();
        event?.stopPropagation();

        youtubeSearch(url).then(({data}) => {
            setIsLoading(false);

            if (data.success) {
                setVideoData(data.data);
            } else {
                setError('URL inválida');
            }
        });
    };

    const download = () => {
        if (!videoData.id) {
            setError('ID incorrecta');
            return;
        }

        setIsLoading(true)

        youtubeDownload({
            id: videoData.id,
            playlist: selectedPlaylist,
            songId: selectedSongId,
            title: selectedSongTitle
        }).then(({data}) => {
            console.log('data', data)
            setIsLoading(false)
        })
    }

    console.log(selectedSongTitle);

    return (
        <div className="Modal Youtube">
            <div className="Modal--header">
                <div className="Modal--close" onClick={() => clearModal()}><i className="fa fa-angle-double-down"/>
                </div>
                <div className="Modal--title"><h1>Youtube</h1></div>
            </div>
            <div className={'Modal__container'}>
                {isLoading && <div>Cargando...</div>}
                {!isLoading && <>
                    <div className={"Youtube__search"}>
                        <label>Enlace de Youtube</label>
                        <form
                            onSubmit={search}
                        >
                            <input
                                type={'text'}
                                value={url}
                                onChange={(e) => {
                                    setUrl(e.target.value)
                                }}
                            />
                            <i
                                className={'fa fa-search'}
                                onClick={search}
                            ></i>
                        </form>
                        {error && <label style={{color: 'red', marginTop: '0.5em'}}>{error}</label>}
                    </div>
                    {videoData?.title && <div className="Youtube__video">
                        <div className="Youtube__video__image"><img src={videoData?.image} alt={videoData?.title}/>
                        </div>
                        <div className="Youtube__video__title">{videoData?.title}</div>
                    </div>}

                    {videoData?.title && !songs?.length && <div>
                        <label>Título</label>
                        <textarea className={"Youtube__songTitle__input"}
                                  onChange={(e) => setSelectedSongTitle(e.currentTarget.value)}>
                            {selectedSongTitle ?? ''}
                        </textarea>
                    </div>
                    }

                    {
                        !!songs?.length &&
                        (
                            <>
                                <div className={"Youtube__help"}>
                                    <div>Selecciona la canción y el artista que más se
                                        adapte.</div>
                                    { selectedSongId && <a href="#" onClick={() => setSelectedSongId(undefined)}>Deshacer</a>}
                                </div>

                                <div className="Youtube__songs">
                                    {
                                        songs.map((song, index) => {
                                            if (selectedSongId && selectedSongId !== song.id) {
                                                return null;
                                            }
                                            return (
                                                <div onClick={() => setSelectedSongId(song.id)}
                                                     className={`Youtube__songs__item ${selectedSongId === song.id ? 'Youtube__songs__item--selected' : ''}`}>
                                                    <div className="Youtube__songs__image">
                                                        <img src={song.image} alt={song.title}/>
                                                    </div>
                                                    <div className="Youtube__songs__info">
                                                        <div
                                                            className="Youtube__songs__title">{song.title} - {song.artist}</div>
                                                    </div>
                                                </div>)
                                        })
                                    }
                                </div>
                            </>)
                    }
                    {videoData?.id && <div className={"Youtube__playlist"}>
                        <label>Selecciona una lista de reproducción</label>
                        <select onChange={(e) => setSelectedPlaylist(e.target.value)}>
                            {playlists.map((playlist) => (
                                <option
                                    value={playlist?.id}
                                    id={playlist?.id}
                                    data-status={
                                        selectedPlaylist === playlist?.id
                                    }
                                    selected={selectedPlaylist === playlist?.id}
                                >
                                    {playlist.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    }
                    {videoData?.id && <div className="Youtube__download" onClick={() => download()}><i
                        className={'fa fa-download'}
                    ></i></div>}
                </>}
            </div>
        </div>
    );
};
