import { Playlist } from './playlist';
import { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { getAllPlaylists, getPlaylist } from '../../api';
import { Options } from './options';
import { useParams } from 'react-router';
import { Controls } from './controls';
import { Playlists } from './playlists';
import { Toolbar } from './toolbar';
import {
    generateEmojiLink,
    generateManifest,
    scrollToActiveTrack,
    scrollToTop,
    setTheme,
    shuffleArray,
    uploadManifest,
} from '../../utils';
import { Youtube } from './youtube';
import { Menu } from './menu';

const Player = () => {
    const [playlistData, setPlaylistData] = useState(window?.playlist);
    const [trackIndex, setTrackIndex] = useState(undefined);
    const [currentTrack, setCurrentTrack] = useState(undefined);
    const [duration, setDuration] = useState(0);
    const [tracks, setTracks] = useState([]);
    const [filteredTracks, setFilteredTracks] = useState([]);
    const [isPlaying, setIsPlaying] = useState(false);
    const [modal, setModal] = useState('');
    const [manifestBlob, setManifestBlob] = useState(undefined);
    const [playlists, setPlaylists] = useState(window?.playlists ?? []);
    const [loading, setLoading] = useState();
    const [timeProgress, setTimeProgress] = useState(0);

    const { playlist, song } = useParams();

    // Swipe effects
    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => {
            let newIndex = trackIndex - 1;
            if (newIndex < 0 || newIndex >= tracks.length) {
                newIndex = 0;
            }
            setTrackIndex(newIndex);
            setCurrentTrack(filteredTracks[newIndex]);
        },
        onSwipedRight: () => {
            let newIndex = trackIndex + 1;
            if (newIndex < 0 || newIndex >= tracks.length) {
                newIndex = 0;
            }
            setTrackIndex(newIndex);
            setCurrentTrack(filteredTracks[newIndex]);
        },
    });

    // autoplay song if url parameter
    useEffect(() => {
        if (song && filteredTracks.length) {
            const songName = atob(song);
            console.log(songName);
            const trackIndex = filteredTracks
                .map((track) => track.name)
                .indexOf(songName);

            if (trackIndex !== -1) {
                setCurrentTrack(filteredTracks[trackIndex]);
            }
        }
    }, [song, filteredTracks]);

    useEffect(() => {
        scrollToActiveTrack();
    }, [isPlaying, trackIndex, currentTrack]);

    useEffect(() => {
        if (playlistData) {
            const shouldGenerateManifest =
                playlistData?.name || playlistData?.root;
            if (shouldGenerateManifest) {
                const manifest = generateManifest({
                    ...playlistData,
                    id: playlist,
                });
                uploadManifest({ manifest, manifestBlob, setManifestBlob });
            }

            document
                .querySelector('#favicon')
                .setAttribute(
                    'href',
                    generateEmojiLink(
                        playlistData.iconColor,
                        playlistData.emoji,
                        64
                    )
                );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playlistData, playlist]);

    // useEffect(() => {
    //   if (trackIndex !== undefined) {
    //     const nextSong = filteredTracks?.[trackIndex + 1];
    //     if (nextSong) {
    //       const link = document.createElement("link");
    //       link.setAttribute("rel", "prefetch");
    //       link.setAttribute("href", nextSong.src);
    //       document.body.append(link);
    //     }
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [trackIndex]);

    useEffect(() => {
        if (window?.playlist?.id) {
            const { tracks, ...playlistData } = window.playlist;

            setTracks(tracks);
            setFilteredTracks(tracks);
            setCurrentTrack(tracks[trackIndex]);
            setPlaylistData(playlistData);
        } else {
            getPlaylist(playlist).then(({ data }) => {
                if (!data.success) {
                    return;
                }
                const newTracks = data.data.tracks.filter((t) => !t.isFolder);

                setTracks(newTracks);
                setFilteredTracks(newTracks);
                setCurrentTrack(newTracks[trackIndex]);
                setPlaylistData(data.data);
            });
        }

        if (window.playlists?.length) {
            setPlaylists(window.playlists);
        } else {
            getAllPlaylists().then(({ data }) => {
                setPlaylists(data.data);
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (playlistData) {
            updatePlaylist(playlistData);
            setTheme(playlistData.theme);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playlistData]);

    useEffect(() => {
        if (modal & playlistData) {
            console.log(playlistData);
            setTheme(playlistData.theme);
        }
    }, [modal, playlistData]);

    const updatePlaylist = (updatedPlaylist) => {
        const newPlaylists = playlists.map((playlist) => {
            if (playlist?.id === updatedPlaylist?.id) {
                return {
                    ...playlist,
                    ...updatedPlaylist,
                };
            }

            return playlist;
        });

        setPlaylists(newPlaylists);
    };

    const selectTrackIndex = (index) => {
        const i = index % tracks.length;
        setTrackIndex(i);
        setCurrentTrack(filteredTracks[i]);
        setIsPlaying(true);
    };

    const shuffle = () => {
        const newTracks = shuffleArray(tracks);
        setFilteredTracks(newTracks);
        setTrackIndex(0);
        setCurrentTrack(newTracks[0]);
        setIsPlaying(true);
    };
    const deShuffle = () => {
        console.log(tracks);
        setFilteredTracks(tracks);
        setTrackIndex(0);
        setCurrentTrack(tracks[0]);
        setIsPlaying(true);
    };

    const sortByLatest = () => {
        const sortedTracks = [...tracks].sort((a, b) => {
            const A = a.date;
            const B = b.date;
            if (A === B) {
                return 0;
            }
            // reverse
            return A > B ? -1 : -0;
        });

        setFilteredTracks(sortedTracks);
        setTrackIndex(0);
        setCurrentTrack(sortedTracks[0]);
        // setIsPlaying(true);
    };

    const filterTracks = (query) => {
        if (!query) {
            setFilteredTracks(tracks);
            return;
        }

        const newTracks = tracks.filter((track) =>
            track.name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredTracks(newTracks);
        scrollToTop();
    };

    if (modal) {
        document.querySelector('body').classList.add('body--with-modal');
    } else {
        document.querySelector('body').classList.remove('body--with-modal');
    }

    const options = () => (
        <Options {...{ modal, setModal, currentTrack, playlistData }} />
    );
    const toolBar = () => (
        <Toolbar
            {...{
                filterTracks,
                shuffle,
                deShuffle,
                sortByLatest,
                isEmpty: tracks?.length && filteredTracks?.length === 0,
                playlistData,
                setPlaylistData,
                setLoading,
            }}
        />
    );

    return (
        <>
            <div className="page">
                <Controls
                    {...{
                        options: options,
                        toolBar: toolBar,
                        currentTrack,
                        duration,
                        isPlaying,
                        setIsPlaying,
                        setCurrentTrack,
                        setDuration,
                        setTrackIndex,
                        timeProgress,
                        setTimeProgress,
                        trackIndex,
                        tracks: filteredTracks,
                        playlistData: playlistData,
                    }}
                />
                <Playlist
                    swipeHandlers={swipeHandlers}
                    tracks={filteredTracks}
                    setTrack={setCurrentTrack}
                    currentTrack={currentTrack}
                    selectTractIndex={selectTrackIndex}
                    viewType={playlistData?.viewType}
                    isEmpty={tracks?.length && filteredTracks?.length === 0}
                    playlistData={playlistData}
                />
            </div>

            {modal === 'playlists' && (
                <Playlists
                    {...{
                        playlists,
                        playlistData,
                        clearModal: () => setModal(null),
                    }}
                />
            )}
            {modal === 'downloader' && (
                <Youtube
                    selectedPlaylist={playlistData?.id}
                    clearModal={() => setModal(null)}
                />
            )}

            {modal === 'menu' && <Menu clearModal={() => setModal(null)} />}
            {loading && (
                <div className={'Loading'}>
                    <div className={'Loading__container'}></div>

                    <div className={'Loading__icon rotating'}>
                        {playlistData.emoji}
                    </div>
                    <div className={'Loading__title'}>{loading?.title}</div>
                    <div className={'Loading__message'}>{loading?.message}</div>
                </div>
            )}
        </>
    );
};
export default Player;
