import axios from 'axios';
import {base64URLencode} from "../utils";

const baseUrl = `${window.configuration.website}/server/api.php?`;

export const getConfigApi = () => axios.get(`${baseUrl}op=config`);
export const getPlaylist = (id) =>
    axios.get(`${baseUrl}op=playlist&id=${id ?? ''}`);
export const getAllPlaylists = () => axios.get(`${baseUrl}op=familyPlaylists`);

export const setCustomConfiguration = (customConfig) => {
    const config = {...customConfig};
    delete config?.elements;
    delete config?.tracks;

    return axios.post(`${baseUrl}op=save-playlist-config`, config);
};

export const spotiSearch = (query) =>
    axios.get(`${baseUrl}op=spoti-search&query=${query}`);

export const spotiDownload = (id, playlist) =>
    axios.get(`${baseUrl}op=spoti-download&id=${id}&playlist=${playlist}`);


export const youtubeSearch = (url) =>
    axios.get(`${baseUrl}op=youtube-search&url=${url}`);

export const youtubeDownload = ({id, playlist, songId, title}) => {
    let path = `${baseUrl}op=youtube-download&id=${id}&playlist=${playlist}`;

    if (songId) {
        path += `&songId=${songId}`;
    }

    if (title) {
        path += `&title=${base64URLencode(title)}`;
    }

    return axios.get(path);
}

export const songInfo = (data) =>
    axios.get(`${baseUrl}op=song-info&data=${btoa(data)}`);


export const updatePlaylist = (playlist) =>  axios.get(`${baseUrl}op=playlist-runner&id=${playlist}`);