import { scrollToBottom } from '../../utils';
import Marquee from 'react-fast-marquee';

export const Options = ({ modal, setModal, currentTrack, playlistData }) => {
    return (
        <div className="Options">
            <div
                className="button change-playlists"
                onClick={() =>
                    modal === 'playlists' ? setModal('') : setModal('playlists')
                }
            >
                <i className="fa fa-bars"></i>
            </div>
            {currentTrack?.name && (
                <div
                    className="track-title"
                    onClick={() => {
                        scrollToBottom();
                    }}
                >
                    <Marquee autoFill={true}>
                        {playlistData?.emoji ? (
                            <span className="track-title__emoji rotating">
                                {playlistData.emoji}
                            </span>
                        ) : (
                            <img src="/images/bananadance.gif" alt="banana" />
                        )}
                        <div>{currentTrack.name}</div>
                    </Marquee>
                </div>
            )}
            <div
                className="button add-track"
                onClick={() =>
                    modal === 'menu' ? setModal('') : setModal('menu')
                }
            >
                <i className="fa fa-plus"></i>
            </div>
        </div>
    );
};
