import React from "react";
import ReactDOM from "react-dom/client";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// import reportWebVitals from "./reportWebVitals";
import Player from "./components/Player";
// import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { register } from 'register-service-worker'
import {Account} from "./components/account";

import "./styles/player.scss";
import "./styles/account.scss";


// const router = createBrowserRouter([
//   {
//     path: "/:playlist/:song",
//     element: <Player />,
//   },
//   {
//     path: "/:playlist",
//     element: <Player />,
//   },
//   {
//     path: "/account",
//     element: <Account />,
//   },
//   {
//     path: "/",
//     element: <Player />,
//   },
// ]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  //   <RouterProvider router={router} />
  // </React.StrictMode>,
    <Player />
);


// register('/service-worker.js', {
//   registrationOptions: { scope: './' },
//   ready (registration) {
//     console.log('Service worker is active.',registration)
//   },
//   registered (registration) {
//     console.log('Service worker has been registered.',registration)
//   },
//   cached (registration) {
//     console.log('Content has been cached for offline use.',registration)
//   },
//   updatefound (registration) {
//     console.log('New content is downloading.',registration)
//   },
//   updated (registration) {
//     console.log('New content is available; please refresh.',registration)
//   },
//   offline () {
//     console.log('No internet connection found. App is running in offline mode.')
//   },
//   error (error) {
//     console.error('Error during service worker registration:', error)
//   }
// })

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
