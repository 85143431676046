import { encode, decode } from 'js-base64';

export const DEFAULT_VIEW_TYPE = 'carousel';
export const DEFAULT_EMOJI = '🎵';

export const shuffleArray2 = (array) => {
    const newArray = [...array];

    var currentIndex = newArray.length,
        temporaryValue,
        randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Create a random index to pick from the original array
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // Cache the value, and swap it with the current element
        temporaryValue = newArray[currentIndex];
        newArray[currentIndex] = newArray[randomIndex];
        newArray[randomIndex] = temporaryValue;
    }

    return newArray;
};

export const shuffleArray3 = (arr) => {
    let i = 0,
        res = [],
        index

    while (i <= arr.length - 1) {
        index = Math.floor(Math.random() * arr.length)

        if (!res.includes(arr[index])) {
            res.push(arr[index])
            i++
        }
    }

    return res
}

export const shuffleArray = (array) => {
    let output = [...array];

    for(let i = output.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * i)
        const temp = output[i]
        output[i] = output[j]
        output[j] = temp
    }

    return output;
}

export const getBrowserArtWorks = async (imageSrc) => {
    const sizes = [128, 512];

    const promises = sizes.map((size) => {
        return new Promise((resolve) => {
            let blobURL;
            const image = new Image();
            image.crossOrigin = 'anonymous';
            image.src = imageSrc;

            image.addEventListener('load', async () => {
                const canvas = document.createElement('canvas');
                canvas.width = size;
                canvas.height = size;
                const context = canvas.getContext('2d');
                context.drawImage(image, 0, 0, canvas.width, canvas.height);
                canvas.toBlob((blob) => {
                    if (!blob) return;
                    if (blobURL) URL.revokeObjectURL(blobURL);
                    blobURL = URL.createObjectURL(blob);
                    // navigator.mediaSession.metadata = new MediaMetadata({
                    //     title: 'Web Streams Explained',
                    //     artist: 'Syntax - Tasty Web Development Treats',
                    //     artwork: [
                    //         {
                    //             src: blobURL,
                    //             type: blob.type,
                    //             sizes: `${canvas.width}x${canvas.height}`,
                    //         },
                    //     ],
                    // });
                    resolve({
                        src: blobURL,
                        type: blob.type,
                        sizes: `${canvas.width}x${canvas.height}`,
                    });
                });
            });
        });
    });

    return Promise.all(promises);
};

export const scrollToActiveTrack = () => {
    document
        .querySelector('.Playlist__item.Playlist__item--active')
        ?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
        });
};

export const scrollToTop = () => {
    const scrollConfig = {
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
    };
    const firstItem = document.querySelector(
        '.Playlist .Playlist__item:first-child'
    );

    if (firstItem) {
        firstItem.scrollIntoView(scrollConfig);
    }

    document.querySelector('.Playlist').scrollIntoView(scrollConfig);
};

export const scrollToBottom = () => {
    const scrollConfig = {
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
    };
    const lastItem = document.querySelector(
        '.Playlist .Playlist__item:last-child'
    );

    if (lastItem) {
        lastItem.scrollIntoView(scrollConfig);
    }

    document.querySelector('.Playlist').scrollIntoView(scrollConfig);
};

export const formatTime = (time) => {
    if (time && !isNaN(time)) {
        const minutes = Math.floor(time / 60);
        const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
        const seconds = Math.floor(time % 60);
        const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
        return `${formatMinutes}:${formatSeconds}`;
    }
    return '';
};

export const generateIconLink = (text1, text2 = null, size = null) => {
    let link = window.configuration.website + '/server/image/image.php?text1=' + text1;

    if (text2) {
        link += '&text2=' + text2;
    }

    if (size && !isNaN(size)) {
        link += '&resize=' + parseInt(size);
    }

    return link;
};

export const generateEmojiLink = (color, emoji, size = null) => {
    return `${window.configuration.website}/server/image/emoji.php?size=${
        size || ''
    }&color=${color?.replace('#', '') || 'FFFFFF'}&emoji=${encodeURI(
        emoji || DEFAULT_EMOJI
    )}`;
};

export const generateManifest = ({
    name,
    root,
    iconColor,
    theme,
    emoji,
    id,
}) => {
    const baseManifest = {
        short_name: 'Music',
        name: 'Music by Tekbreak',
        description: 'My music',
        background_color: '#000000',
        display: 'standalone',
        theme_color: 'white',
        shortcuts: [
            {
                name: 'Player',
                short_name: 'Player',
                description: 'Music player',
                url: '/',
                icons: [
                    {
                        src: '/favicons/android-icon-192x192.png',
                        sizes: '192x192',
                    },
                ],
            },
        ],
        icons: [
            {
                src: 'assets/manifest-icon-192.maskable.png',
                sizes: '192x192',
                type: 'image/png',
                purpose: 'any',
            },

            {
                src: 'assets/manifest-icon-512.maskable.png',
                sizes: '512x512',
                type: 'image/png',
                purpose: 'any',
            },
        ],
    };

    const manifest = { ...baseManifest };
    const url = window.configuration.website + '/' + id;
    const description = `Lista ${name} de ${root}`;

    // manifest.id = url;
    manifest.id = url;
    manifest.name = capitalizeFirstLetter(name);
    manifest.description = description;
    manifest.short_name = capitalizeFirstLetter(name);
    manifest.theme_color = theme;

    // icons (also for shortcuts)
    const icons = manifest.icons.map((icon) => {
        const size = icon.sizes?.split('x')?.[0] ?? '512';

        return {
            ...icon,
            src: generateEmojiLink(iconColor, emoji, size),
        };
    });
    manifest.icons = icons;

    //shortcuts
    manifest.shortcuts = manifest.shortcuts.map((shortcut) => {
        return {
            ...shortcut,
            name: capitalizeFirstLetter(name),
            short_name: capitalizeFirstLetter(name),
            description,
            url,
            icons, // ...from icons
        };
    });

    return manifest;
};

export const uploadManifest = ({ manifest, manifestBlob, setManifestBlob }) => {
    // update manifest
    const stringManifest = JSON.stringify(manifest);

    let blob;
    if (!manifestBlob) {
        blob = new Blob([stringManifest], { type: 'application/json' });
        setManifestBlob(blob);
        const manifestURL = URL.createObjectURL(blob);

        document
            .querySelector('#app-manifest')
            .setAttribute('href', manifestURL);
    } else {
        blob = new Blob([stringManifest], { type: 'application/json' });
        setManifestBlob(blob);
    }
};

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const isEmoji = (text) => {
    const emojiRegex = /\p{Emoji}/u;

    return emojiRegex.test(text);
};

export function getColorByBgColor(bgColor) {
    if (!bgColor) {
        return '';
    }
    return parseInt(bgColor.replace('#', ''), 16) > 0xffffff / 2
        ? '#000'
        : '#fff';
}

export const setTheme = (color) => {
    const textColor = getColorByBgColor(color);
    // control bar and overall color
    const display = document.querySelector('.display');
    display.style.backgroundColor = color;
    display.style.color = textColor;

    // Modals
    const modals = [...document.querySelectorAll('.Modal')];
    if (modals.length) {
        modals.forEach(modal => {
            modal.style.backgroundColor = color;
            modal.style.color = textColor;

            modal.querySelector('h1').style.borderColor = textColor;
        })
    }
};

export function base64URLencode(str) {
    // const utf8Arr = new TextEncoder().encode(str);
    // const base64Encoded = btoa(utf8Arr);
    // return base64Encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    return encode(str)
}
export function base64URLdecode(str) {
    // const base64Encoded = str.replace(/-/g, '+').replace(/_/g, '/');
    // const padding = str.length % 4 === 0 ? '' : '='.repeat(4 - (str.length % 4));
    // const base64WithPadding = base64Encoded + padding;
    // return atob(base64WithPadding)
    //     .split('')
    //     .map(char => String.fromCharCode(char.charCodeAt(0)));
    return decode(str)
}
